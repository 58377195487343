var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c(
        "van-list",
        {
          attrs: {
            finished: _vm.finished,
            "finished-text": "没有更多了",
            offset: "300",
            "immediate-check": false,
          },
          on: { load: _vm.getConversationList },
          model: {
            value: _vm.loading,
            callback: function ($$v) {
              _vm.loading = $$v
            },
            expression: "loading",
          },
        },
        [
          _c(
            "ul",
            [
              !_vm.doctorList.length
                ? _c("div", { staticClass: "no-data flex f-jc-c f-ai-c" }, [
                    _c("div", { staticClass: "flex f-d-c f-ai-c" }, [
                      _c("img", {
                        attrs: {
                          src: require("../../assets/images/no-data.png"),
                          alt: "",
                        },
                      }),
                      _c("span", [_vm._v("暂无数据")]),
                    ]),
                  ])
                : _vm._e(),
              _vm._l(_vm.doctorList, function (item, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    staticClass: "flex",
                    on: {
                      click: function ($event) {
                        return _vm.toDoctorDetail(
                          item.doctorId,
                          item.conversationId,
                          item.patientId
                        )
                      },
                    },
                  },
                  [
                    item.doctor.avatar
                      ? _c(
                          "div",
                          {
                            staticClass: "avatar",
                            style: { background: `url(${item.doctor.avatar})` },
                          },
                          [
                            item.newMessage
                              ? _c("span", { staticClass: "msg-count" }, [
                                  _vm._v(
                                    _vm._s(
                                      +item.newMessage > 99
                                        ? `99+`
                                        : item.newMessage
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        )
                      : _c("div", { staticClass: "avatar avatar-d" }, [
                          item.newMessage
                            ? _c("span", { staticClass: "msg-count" }, [
                                _vm._v(
                                  _vm._s(
                                    +item.newMessage > 99
                                      ? `99+`
                                      : item.newMessage
                                  )
                                ),
                              ])
                            : _vm._e(),
                        ]),
                    _c("div", { staticClass: "right" }, [
                      _c("div", { staticClass: "doctor-info flex f-jc-sb" }, [
                        _c("div", { staticClass: "name flex" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(item.doctor.name) +
                                " " +
                                _vm._s(item.doctor.titleName)
                            ),
                          ]),
                          _c("span", [_vm._v(_vm._s(item.doctor.deptName))]),
                        ]),
                        _c("span", { staticClass: "time" }, [
                          _vm._v(
                            _vm._s(
                              _vm.dateFormat(
                                new Date(item.updatedAt),
                                "MM月dd日"
                              )
                            )
                          ),
                        ]),
                      ]),
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm.cutdownString(
                              item.doctor.description
                                ? item.doctor.description
                                : "无",
                              15
                            )
                          )
                        ),
                      ]),
                      _c("div", { staticClass: "msg" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.cutdownString(item.lastMessage, 18)) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("span", { staticClass: "patient" }, [
                      _vm._v(_vm._s(item.patient.name)),
                    ]),
                  ]
                )
              }),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }