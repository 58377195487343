import { render, staticRenderFns } from "./my-doctor.vue?vue&type=template&id=63e4938a&scoped=true"
import script from "./my-doctor.vue?vue&type=script&lang=js"
export * from "./my-doctor.vue?vue&type=script&lang=js"
import style0 from "./my-doctor.vue?vue&type=style&index=0&id=63e4938a&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63e4938a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/wxq/workspace/ssl-odat-patient-wechat/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('63e4938a')) {
      api.createRecord('63e4938a', component.options)
    } else {
      api.reload('63e4938a', component.options)
    }
    module.hot.accept("./my-doctor.vue?vue&type=template&id=63e4938a&scoped=true", function () {
      api.rerender('63e4938a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/doctor/my-doctor.vue"
export default component.exports